import React, { useState, useReducer, ChangeEvent, useEffect } from 'react'
import { Link } from 'gatsby'
import * as ForgotPassword from './ForgotPassword.module.scss'
import { passwordResetConfirm } from '../../../api/passwordreset'
import classNames from 'classnames'

interface ResetResponse {
  success: boolean
  data?: {
    message: string
  }
}

export const ResetPasswordForm = () => {
  const [fieldType, setFieldType] = useState<'text' | 'password'>('text')
  const [tmpPassword, setTmpPassword] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [formValues, setFormValues] = useReducer(
    (currentValues, newValues) => ({ ...currentValues, ...newValues }),
    []
  )

  useEffect(() => {
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    const params = new URLSearchParams(
      typeof window !== 'undefined' ? location.search : undefined
    )
    const resetKey = params.get('key')
    const userlogin = decodeURIComponent(params.get('login') ?? '')
    const tmpPassword = decodeURIComponent(params.get('tmp_password') ?? '')
    if (resetKey && userlogin && tmpPassword) {
      setTmpPassword(tmpPassword)
      setFormValues({ password: tmpPassword })
      setFormValues({ login: userlogin })
      setFormValues({ key: resetKey })
    }
  }, [])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false)
    setError(null)
    const { name, value } = event.target

    setFormValues({ [name]: value })
  }

  const handleFormSubmission = () => {
    setSubmitting(true)
    setError(null)
    passwordResetConfirm({ data: formValues })
      .then((response: ResetResponse) => {
        if (response.success === true) {
          setSubmitted(true)
        } else {
          setError(response?.data?.message || 'Oops, something went wrong')
        }
      })
      .catch((response: string) => {
        setError(`Oops, there was a problem signing you in: ${response}`)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <div className={ForgotPassword.Wrapper}>
      <form className={ForgotPassword.Form}>
        <p>
          <label htmlFor="password">Reset your password</label>
          <div className={ForgotPassword.InputWrapper}>
            <div
              className={classNames({
                [ForgotPassword.ShowPassword]: true,
                [ForgotPassword.ShowPasswordActive]: fieldType === 'text',
                [ForgotPassword.ShowPasswordInactive]: fieldType === 'password'
              })}
              onClick={() =>
                setFieldType(fieldType === 'password' ? 'text' : 'password')
              }
              title={fieldType === 'text' ? 'Hide password' : 'Show password'}
            />
            <input
              type={fieldType}
              id="password"
              name="password"
              defaultValue={tmpPassword ?? ''}
              onChange={handleInputChange}
            />
          </div>
        </p>
        <p className={ForgotPassword.ButtonRow}>
          <button
            type="button"
            onClick={() => handleFormSubmission()}
            disabled={submitted || submitting}
          >
            {submitting ? <>Submitting</> : 'Reset password'}
          </button>
          {error && (
            <span
              className={ForgotPassword.Alert}
              dangerouslySetInnerHTML={{ __html: error || '' }}
            />
          )}
          {submitted && (
            <span className={ForgotPassword.Alert}>
              Your password has been changed. You may now{' '}
              <Link to={'/log-in'}>{'sign in'}</Link>.
            </span>
          )}
        </p>
        <p className={ForgotPassword.SmallRow}>
          <small>
            Already have an account? <Link to={`/sign-in`}>Sign in</Link>.
          </small>
        </p>
      </form>
    </div>
  )
}

export default ResetPasswordForm
