import React, { useState, useReducer, ChangeEvent, useEffect } from 'react'
import { Link } from 'gatsby'
import * as ForgotPassword from './ForgotPassword.module.scss'
import { passwordReset } from '../../../api/passwordreset'

const emailIsValid = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}

interface Props {
  site: string
}

export const ForgotPasswordForm = ({ site }: Props) => {
  const [error, setError] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [formValues, setFormValues] = useReducer(
    (currentValues, newValues) => ({ ...currentValues, ...newValues }),
    []
  )
  const formFields = ['emailaddress']

  useEffect(() => {
    setFormValues({ site: site })
  }, [])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false)
    setError(null)
    const { name, value } = event.target

    setFormValues({ [name]: value })
  }

  const handleFormSubmission = () => {
    setSubmitting(true)
    setError(null)
    passwordReset({ data: formValues })
      .then(response => {
        if (response.data.success === 1) {
          setSubmitted(true)
        } else {
          throw new Error(response.data.details)
        }
      })
      .catch((err: any) => {
        if (err.message === 'User not found') {
          setError(
            `It appears that we don't have an account with that email address. If you think this is a mistake, please contact us by emailing <a href="mailto:hq@muddystilettos.co.uk">hq@muddystilettos.co.uk</a>.`
          )
        } else {
          setError(`Oops, there was a problem submitting your request.`)
        }
      })
      .finally(() => setSubmitting(false))
  }

  const isValid = () => {
    if (formValues.email && !emailIsValid(formValues.email)) return true

    return !formFields.every(
      required => formValues[required] && formValues[required].length > 0
    )
  }

  return (
    <div className={ForgotPassword.Wrapper}>
      <form className={ForgotPassword.Form}>
        <p>
          <label htmlFor="emailaddress">Email address</label>
          <input
            type={`email`}
            id="emailaddress"
            name="emailaddress"
            value={formValues.emailaddress || ''}
            onChange={handleInputChange}
          />
        </p>
        <p className={ForgotPassword.ButtonRow}>
          <button
            type="button"
            onClick={() => handleFormSubmission()}
            disabled={submitted || submitting || isValid()}
          >
            {submitting ? <>Submitting</> : 'Reset password'}
          </button>
          {error && (
            <span
              className={ForgotPassword.Alert}
              dangerouslySetInnerHTML={{ __html: error || '' }}
            />
          )}
          {submitted && (
            <span
              className={ForgotPassword.Alert}
            >{`${'Please check your email for your password reset link'}`}</span>
          )}
        </p>
        <p className={ForgotPassword.SmallRow}>
          <small>
            Already have an account? <Link to={`/sign-in`}>Sign in</Link>.
          </small>
        </p>
      </form>
    </div>
  )
}

export default ForgotPasswordForm
