interface IPasswordReset {
  data: {
    emailaddress: string
    site: string
  }
}

interface IPasswordResetConfirm {
  data: {
    login: string
    password: string
    key: string
  }
}

export const passwordReset = async ({
  data: { emailaddress, site }
}: IPasswordReset) => {
  const url = `https://dev.muddystilettos.co.uk/wp-json/muddy/reset-password`
  const passwordResetData = new FormData()
  passwordResetData.append('username', emailaddress)
  passwordResetData.append('site', site)
  const response = await fetch(url, {
    method: 'post',
    body: passwordResetData
  })

  return response.json()
}

export const passwordResetConfirm = async ({
  data: { login, password, key }
}: IPasswordResetConfirm) => {
  const url = `https://dev.muddystilettos.co.uk/wp-json/muddy/reset-password-confirm`
  const passwordResetData = new FormData()
  passwordResetData.append('login', login)
  passwordResetData.append('password', password)
  passwordResetData.append('key', key)
  const response = await fetch(url, {
    method: 'post',
    body: passwordResetData
  })

  return response.json()
}
