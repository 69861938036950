import React, { useEffect, useState } from 'react'
import Header from '../components/Header/Header'
import ForgotPasswordForm from '../components/layoutComponents/forgotPassword/ForgotPassword'
import Footer from '../components/Footer/Footer'
import Intro from '../components/organisms/intro/Intro'
import Logomark from '../components/Logomark/Logomark'
import { Helmet } from 'react-helmet'
import ResetPasswordForm from '../components/layoutComponents/forgotPassword/ResetPassword'
import { graphql } from 'gatsby'
import { MULTISITE_COUNTIES } from '../lib/data/Sites'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        siteId
      }
    }
  }
`

interface ForgotPasswordProps {
  data: {
    wpgraphql: {
      generalSettings: {
        siteId: number
      }
    }
  }
}

const ForgotPassword = ({
  data: {
    wpgraphql: {
      generalSettings: { siteId }
    }
  }
}: ForgotPasswordProps) => {
  const [action, setAction] = useState<'fp' | 'rp'>('fp')

  const siteUrl = MULTISITE_COUNTIES.find(c => c.id === siteId)?.url

  useEffect(() => {
    if (typeof window === 'undefined') return
    // eslint-disable-next-line node/no-unsupported-features/node-builtins
    const urlParams = new URLSearchParams(window.location.search)
    const actionParam = urlParams.get('action')
    if (actionParam === 'rp') {
      setAction('rp')
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Forgot Your Password | Muddy Stilettos</title>
      </Helmet>
      <Header />
      <Intro>
        <h1>
          {action === 'fp' ? `Forgot Your Password?` : `Reset Your Password`}
        </h1>
        <p>
          {action === 'fp'
            ? `To reset your password enter your email address into the form below.`
            : `Enter your new password into the form below.`}
        </p>
      </Intro>
      {action === 'fp' ? (
        <ForgotPasswordForm site={siteUrl ?? ''} />
      ) : (
        <ResetPasswordForm />
      )}
      <Logomark />
      <Footer />
    </>
  )
}

export default ForgotPassword
